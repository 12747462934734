import { BrowserRouter as Router } from 'react-router-dom';
import { Main } from './components/Main';
import './styles/global.scss';
import { useTranslation } from 'react-i18next';


function App() {
  const [t, i18n] = useTranslation("global");
  return (
    <Router>
      <Main />
    </Router>
  );
}

export default App;