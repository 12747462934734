import "../../styles/servicios.scss";
import bue from "../../assets/bue.svg";
import turing from "../../assets/turing.svg";
import note from "../../assets/notebox.svg";
import soe from "../../assets/soe-icon.svg";
import tick from "../../assets/tick-inverse.svg";
import { useTranslation } from "react-i18next";

export const Servicios = () => {
  const [t, i18n] = useTranslation("global");
  const archivoSuite = process.env.PUBLIC_URL + "/suite-electoral.pdf";
  const archivoBUE = process.env.PUBLIC_URL + "/brochure_BUE.pdf";
  const archivoNotebox = process.env.PUBLIC_URL + "/brochure_Notebox.pdf";
  const archivoTuring = process.env.PUBLIC_URL + "/brochure_Turing.pdf";
  const archivoAntecedentes = process.env.PUBLIC_URL + "/antecedentes-resumidos.pdf";
  return (
    <div className="servicios-container">
      {/*   <div className="cards">
        <div className="card-bue">
          <a href="https://xoolix.com" target="_blank" className="card-link">
            <div className="bue-icon">
              <img src={bue} alt="Logo" className="servicios-icon" />
            </div>
          </a>
          <div className="text">
            <h4>BUE</h4>
            <span>BOLETA ÚNICA ELECTRÓNICA</span>
          </div>
        </div>
      </div> */}
      <div className="items">
        <div className="item">
          <a href={archivoBUE} download className="link-bue" />
          <div className="text">
            <h4>BUE</h4>
            <span>{t('servicios.tab1')}</span>
          </div>
        </div>
        <div className="item">
          <a href={archivoTuring} download className="link-turing" />
          <div className="text">
            <h4>Turing</h4>
            <span>{t('servicios.tab2')}</span>
          </div>
        </div>
        <div className="item">
          <a href={archivoNotebox} download className="link-notebox" />
          <div className="text">
            <h4>Notebox</h4>
            <span>{t('servicios.tab3')}</span>
          </div>
        </div>
        <div className="item">
          {/* <a href={archivoNotebox} download className="link-soe" /> */}
          <a className="link-soe" /> 
          <div className="text">
            <h4>Soe</h4>
            <span>{t('servicios.tab4')}</span>
          </div>
        </div>
      </div>
      <div className="data">
        <h3>{t('servicios.title')}</h3>
        <p>{t('servicios.parrafo')}</p>
        <div className="number">
          <div className="number-data">
            <img src={tick} alt="Logo" className="logo" />
            <span>+80</span>
          </div>
          <p className="uppercase">{t('servicios.millones')}</p>
          <p className="lowercase">{t('servicios.votos')}</p>
        </div>
        <div className="links">
          <a href={archivoSuite} download className="link transparent">
          {t('servicios.brochure')}
          </a>
          <a href={archivoAntecedentes} download className="link transparent">
          {t('servicios.antecedentes')}
          </a>
        </div>
      </div>
    </div>
  );
};
