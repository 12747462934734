import '../../styles/detalleServicio.scss';
// @ts-ignore
import soe from '../../assets/SOE.jpg';
// @ts-ignore
import soeLogo from '../../assets/soe.svg';

import { useTranslation } from 'react-i18next';

export const Soe = () => {
  const [t, i18n] = useTranslation("global");
  return (
    <div className='flex-container servicio-container column'>
        <div className="soeTitle">
        <img src={soeLogo} alt="Logo 1" />
      </div>
        {/* <h2 className='title-servicio'>SOE</h2> */}
        <div className='descrption'>
            <h3>{t('soe.title')}</h3>
            <p>{t('soe.texto1')}</p>
        </div>
        <div className="contenedor-video">
        <div className="contenedor-img">
        <img src={soe} alt="Logo" className="logo" />
        </div>
        </div>
        <div className='detalle'>
          <h3>{t('soe.title2')}</h3>
          <p>{t('soe.texto2')}</p>
        </div>
    </div>
  )
}
