import '../../styles/detalleServicio.scss';
// @ts-ignore
//import millones from '../../assets/bue10millones.svg';
import millones from '../../assets/bue10millones.png';

import { useTranslation } from 'react-i18next';

export const Antecedentes = () => {
  const [t, i18n] = useTranslation("global");
  return (
    <div className='antecedentes flex-container column'>
        <h3>{t('boleta.subTitle')}</h3>
        <div className='antecedentes-datos'>
            <div className='number'>
            <img src={millones} alt="Logo" className="millones-icon" />
            </div>
            <div className='antecedentes-texto'>
                <h3>{t('boleta.titleAntecedentes1')} <br />{t('boleta.titleAntecedentes2')}</h3>
                <div className='datos-texto'>
                    <p>+1000 {t('boleta.item1')}</p>
                    <p>+500K {t('boleta.item2')}</p>
                    <p>+45 {t('boleta.item3')}</p>
                </div>
            </div>
        </div>
    </div>
  )
}
