import '../../styles/footer.scss';
// @ts-ignore
import phone from '../../assets/icon-phone.svg';
// @ts-ignore
import email from '../../assets/icon-email.svg';
// @ts-ignore
import location from '../../assets/location.svg';

export const Footer = () => {
  return (
    <div id="footer" className='footer-container'>
       <div className='flex-container'>
       <div className="container-footer">
      {/* <div className="column-f title-column">
        <h2>Media</h2>
        <p>Pamela Cuomo - Mapa Comunicacion</p>
        <p>pcuomo@mapa-comunicacion.com</p>
        <p>+54 9 11 4089-6667</p>
      </div> */}
      <div className="column-f">
        <p><i className="icon"><img src={phone} alt="" /></i>+54 11 6091-1540</p>
      </div>
      <div className="column-f">
        <p><i className="icon"><img src={email} alt="" /></i>info@msa.com.ar</p>
      </div>
      <div className="column-f">
        <p><i className="icon"><img src={location} alt="" /></i>Atuel 362, Distrito Tecnológico Parque Patricios (C1437EPD) Buenos Aires</p>
      </div>
    </div>
       </div>
    </div>
  )
}
