import { useTranslation } from 'react-i18next';
import '../../styles/servicios.scss';

export const Impacto = () => {
  const [t, i18n] = useTranslation("global");
  return (
    //<div className='flex-container column'>
    <div className='impacto-container column'>
        <h2 className='title'>{t('impacto.title')}</h2>
        <div className='datos-impacto'>
            <div className='number'>
            <span className='color'>200+</span>
            <p>{t('impacto.number1')}</p>
            </div>
            <div className='number'>
            <span className='color'>2000+</span>
            <p>{t('impacto.number2')}</p>
            </div>
        </div>
    </div>
  )
}
