import "../../styles/detalleServicio.scss";
import { Antecedentes } from "./Antecedentes";
import { ItemList } from "./ItemList";
// @ts-ignore
import bue from "../../assets/BUE.mp4";
// @ts-ignore
import bueLogo from "../../assets/bue.svg";
import { useTranslation } from "react-i18next";

export const BoletaUnica = () => {
  const [t, i18n] = useTranslation("global");
  const listConfig = [
    {
      text: t("boleta.ben1"),
    },
    {
      text: t("boleta.ben2"),
    },
    {
      text: t("boleta.ben3"),
    },
    {
      text: t("boleta.ben4"),
    },
    {
      text: t("boleta.ben5"),
    },
  ];

  return (
    <div className="flex-container servicio-container column">
      <div className="bueTitle">
        <img src={bueLogo} alt="Logo 1" />
      </div>
      {/* <h2 className="title-servicio">BUE</h2> */}
      <div className="descrption">
        <h3>{t("boleta.title")}</h3>
        <p dangerouslySetInnerHTML={{ __html: t("boleta.texto1") } as any} />
        <br />
        <br />
        <p dangerouslySetInnerHTML={{ __html: t("boleta.texto2") } as any} />
        <br />
        <br />
        <p dangerouslySetInnerHTML={{ __html: t("boleta.texto3") } as any} />
      </div>
      <div className="contenedor-video">
        <video
          src={bue}
          controls
          loop
          autoPlay
          muted
          width="100%"
          height="auto"
        ></video>
      </div>
      <div className="detalle">
        <h3>BENEFICIOS DE ESTA SOLUCIÓN</h3>
        <ItemList listConfig={listConfig} />
      </div>
      <Antecedentes />
      <div className="line-bottom"></div>
    </div>
  );
};
