import React, { useEffect } from "react";
import { Cover } from "../components/cover/Cover";
import { Header } from "../components/Header";
import { Servicios } from "../components/servicios/Servicios";
import { Impacto } from "../components/servicios/Impacto";
import { BoletaUnica } from "../components/detalleServicios/BoletaUnica";
import { Turing } from "../components/detalleServicios/Turing";
import { Notebox } from "../components/detalleServicios/Notebox";
import { Soe } from "../components/detalleServicios/Soe";
import { Contacto } from "../components/contacto/contacto";
import { Footer } from "../components/footer/Footer";

//import AOS from 'aos'
import "aos/dist/aos.css";
function ScrollAnimation() {
  useEffect(() => {
    const Aos = require("aos");
    Aos.init({ duration: 2000 });
  }, []);

  return null;
}
export const Home = () => {
  return (
    <>
      <ScrollAnimation />
      <div className="animation" data-aos="fade">
        <Header />
      </div>
      <div className="animation" data-aos="fade" data-aos-delay="300">
        <Cover />
      </div>
      <div className="animation" data-aos="fade">
        <Servicios />
      </div>
      <div className="animation" data-aos="fade">
        <Impacto />
      </div>
      <div className="animation" data-aos="fade">
        <BoletaUnica />
      </div>
      <div className="animation" data-aos="fade">
        <Turing />
      </div>
      <div className="animation" data-aos="fade">
        <Notebox />
      </div>
      <div className="animation" data-aos="fade">
        <Soe />
      </div>
      <div className="animation" data-aos="fade">
        <Contacto />
      </div>
      <div className="animation" data-aos="fade" data-aos-delay="300">
        <Footer />
      </div>
    </>
  );
};
