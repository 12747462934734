import "../../styles/detalleServicio.scss";
import { ItemList } from "./ItemList";
// @ts-ignore
import turing from "../../assets/turing-video.mp4";
// @ts-ignore
import turingLogo from "../../assets/turing.svg";

import { useTranslation } from "react-i18next";

export const Turing = () => {
  const [t, i18n] = useTranslation("global");
  const listConfig = [
    {
      text: t("turing.ben1"),
    },
    {
      text: t("turing.ben2"),
    },
    {
      text: t("turing.ben3"),
    },
    {
      text: t("turing.ben4"),
    },
  ];

  return (
    <div className="flex-container servicio-container column">
      <div className="turingTitle">
        <img src={turingLogo} alt="Logo turing" />
      </div>
      {/* <h2 className="title-servicio">TURING</h2> */}
      <div className="descrption">
        <h3>{t("turing.title")}</h3>
        <p>
          {t("turing.texto1")}
          <br />
          <br />
          <b>{t("turing.texto2")}</b>
          <br />
          &#8226; {t("turing.texto3")}
          <br />
          &#8226; {t("turing.texto4")}
          <br />
          <br />
          {t("turing.texto5")}
        </p>
      </div>
      <div className="contenedor-video">
        <video
          src={turing}
          controls
          loop
          autoPlay
          muted
          width="100%"
          height="auto"
        ></video>
      </div>
      <div className="detalle">
        <h3> {t("turing.beneficios")}</h3>
        <ItemList listConfig={listConfig} />
      </div>
      <div className="line-bottom"></div>
    </div>
  );
};
