import '../../styles/detalleServicio.scss';
import tick from '../../assets/tick.svg'

type listProps = {
  listConfig: any[],
}

export const ItemList = ({ listConfig }: listProps) => {
  return (
    <>
    {listConfig.map((item: any, index: number) => (
      <div key={index} className="item">
        <div className="icono"> <img src={tick} alt="Icono" width="20" height="20" /></div>
        <div className="texto">{item && item.text}</div>
      </div>
    ))}
  </>
  )
}
