import '../styles/header.scss';
import logo from '../assets/comitia-logo.svg';
import logoSuite from '../assets/logo-suite.svg';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

export const Header = () => {
  const [t, i18n] = useTranslation("global");
  const handleContactButtonClick = () => {
    // Obtener el elemento del footer por su id
    const footerElement = document.getElementById('footer');

    // Hacer scroll hasta el elemento del footer
    if (footerElement) {
      footerElement.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const [language, setLanguage] = useState(true)
  const selectedLang = () => setLanguage(!language)
  return (
    <header className="header">
    <div className="logo-container">
      <img src={logoSuite} alt="Logo" className="logo-suite" />
      <img src={logo} alt="Logo" className="logo" />
    </div>
    <div className='right-header'>
      <div className='lang-responsive show'>
        <button onClick={() => { i18n.changeLanguage("es"); selectedLang() }}className={language ? "lang-selected" : "none-selected"}>ES</button>
        <span> | </span>
        <button onClick={() => {i18n.changeLanguage("en"); selectedLang()}} className={!language ? "lang-selected" : "none-selected"}>EN</button>
      </div>
      <div className="button-container">
        <button className="header-button" onClick={handleContactButtonClick}>{t('header.contact')}</button>
      </div>
    </div>
  </header>
  )
}
