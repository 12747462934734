import { Routes, Route } from 'react-router-dom';
import { Home } from '../pages/Home';


export const Main = () => {
  return (
    <Routes>
      <Route index path='/' element={<Home/>}></Route>
    </Routes>
  )
}