import "../../styles/cover.scss";
// @ts-ignore
import videoManifiesto from "../../assets/VIDEO_MANIFIESTO.mp4";
// @ts-ignore
import iram from "../../assets/iram.jpg";
// @ts-ignore
import iso from "../../assets/iso.png";
// @ts-ignore
import adreani from "../../assets/andreani.jpg";
// @ts-ignore
import aws from "../../assets/aws.png";
// @ts-ignore
import intel from "../../assets/intel.png";
// @ts-ignore
import st from "../../assets/st.png";
import { useTranslation } from "react-i18next";

export const Cover = () => {
  const [t, i18n] = useTranslation("global");
  return (
    <>
       <div className="cover-text">
        <h3>
        {t('cover.video1')}
        </h3>
        <p>
        {t('cover.video2')}
        </p>
      </div>
      <div className="container">
        <div className="left-part">
          <h2>{t('cover.title1')}&nbsp; {t('cover.title2')}</h2>
        </div>
        <div className="right-part">
          <video
            src={videoManifiesto}
            controls
            loop
            autoPlay
            muted
            width="100%"
            height="auto"
          ></video>
        </div>
      </div>
      <div className="component">
        <div className="left-column">
          <h2>PARTNERSHIPS</h2>
          <div className="logos">
            <img src={adreani} alt="Logo 1" />
            <img src={aws} alt="Logo 1" />
            <img src={intel} alt="Logo 1" />
            <img src={st} alt="Logo 1" />
          </div>
          <p>{t('cover.partnership')}</p>
        </div>
        <div className="right-column">
          <h2
            dangerouslySetInnerHTML={
              { __html: t('cover.calidad') } as any
            }
          />
          <div className="text-logos">
            <p>{t('cover.calidadText')}</p>
            <div className="logos">
              <img src={iram} alt="Logo 1" />
              <img src={iso} alt="Logo 1" />
            </div>
          </div>
          <p
            className="sub-text"
            dangerouslySetInnerHTML={
              { __html: t('cover.politic') } as any
            }
          />
        </div>
      </div>
     
    </>
  );
};
