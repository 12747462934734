import '../../styles/detalleServicio.scss';
import { ItemList } from './ItemList';
// @ts-ignore
import notebox from '../../assets/notebox.jpg';
// @ts-ignore
import noteboxLogo from '../../assets/notebox.svg';
import { useTranslation } from 'react-i18next';

export const Notebox = () => {
  const [t, i18n] = useTranslation("global");
  const listConfig = [
    {
        "text": t('notebox.ben1'),
      },
      {
        "text": t('notebox.ben'),
      },
      {
        "text": t('notebox.ben3'),
      },
      {
        "text": t('notebox.ben4'),
      },
      {
        "text": t('notebox.ben5'),
      },
      {
        "text": t('notebox.ben6'),
      },
]

  return (
    <div className='flex-container servicio-container column'>
        <div className="noteboxTitle">
        <img src={noteboxLogo} alt="Logo 1" />
      </div>
        {/* <h2 className='title-servicio'>NOTEBOX</h2> */}
        <div className='descrption'>
            <h3>{t('notebox.title')}</h3>
            <p>
            {t('notebox.texto1')}</p>
        </div>
        <div className="contenedor-img">
        <img src={notebox} alt="Logo" className="logo" />
        </div>
        <div className='detalle'>
          <h3>{t('notebox.beneficios')}</h3>
          <ItemList listConfig={listConfig} />
        </div>
        <div className='line-bottom'></div>
    </div>
  )
}
